body {
  margin: 0;
  padding: 0;
  background-color: #1a202c;
  /* Tailwind's gray-800 */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

footer {
  background-color: #1a202c;
  /* Ensure footer matches the background */
  color: white;
}

.min-h-screen {
  min-height: 100vh;
}

/* Placeholder text color */
input::placeholder {
  color: black;
}

/* Text color for featured properties */
.featured-property-text {
  color: white;
}